import React from "react"
import DocumentLayout from "../components/layout/document"

const DownloadPage= () => {
    return (
        <DocumentLayout>
            <p>ご利用になる環境のアプリを下記ストアサイトよりインストールください。</p>
            <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
                <div>
                    <div class="is-size-4 mt-5 mb-2 has-text-centered">iOS</div>
                    <a href="https://apps.apple.com/us/app/booklooper/id552284132?itsct=apps_box&amp;itscg=30200" className="appstore-badge-link" target="_blank" rel="noopener noreferrer">
                        <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-US?size=250x83&amp;releaseDate=1348185600&h=22424fae687f2315d1a3d5054052d074" alt="Download on the App Store" className="appstore-badge-img"/>
                    </a>
                </div>
                <div>
                    <div class="is-size-4 mt-5 mb-0 has-text-centered">Android<span className="is-size-6">(スマートフォン)</span></div>
                    <a href='https://play.google.com/store/apps/details?id=jp.co.kmsi.booklooper.mobile' target="_blank" rel="noopener noreferrer">
                        <img alt='Google Play で手に入れよう' src='https://play.google.com/intl/ja/badges/static/images/badges/ja_badge_web_generic.png' className="google-play-badge"/>
                    </a>
                </div>
                <div>
                    <div class="is-size-4 mt-5 mb-0 has-text-centered">Android<span className="is-size-6">(タブレット)</span></div>
                    <a href='https://play.google.com/store/apps/details?id=jp.co.kccs.BookLooper' target="_blank" rel="noopener noreferrer">
                        <img alt='Google Play で手に入れよう' src='https://play.google.com/intl/ja/badges/static/images/badges/ja_badge_web_generic.png' className="google-play-badge"/>
                    </a>
                </div>
                <div>
                    <div class="is-size-4 mt-5 mb-2 has-text-centered">Windows</div>
                    <a href='//www.microsoft.com/store/apps/9nblgggz36wg?cid=storebadge&ocid=badge' target="_blank" rel="noopener noreferrer">
                        <img src='https://developer.microsoft.com/store/badges/images/Japanese_-get-it-from-MS.png' alt='Japanese badge' className="windows-store-badge" />
                    </a>
                </div>
                {/* <div>
                    <div class="is-size-4 mt-5 mb-2 has-text-centered">macOS</div>
                </div> */}
            </div>
            <p className="mt-6">
                <small>タブレット端末やスマートフォンについては、機種及びインターネット接続環境等により一部機能等が利用できないこともあります。また、お使いのハードウェア、ソフトウェア環境およびご利用方法によっては、必要環境を満たしていても動作しない場合がありますので、あらかじめご了承ください。</small>
            </p>
        </DocumentLayout>
    )
 }
export default DownloadPage;